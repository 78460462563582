import React from "react";

import { ApplicationFormType } from "@/components/forms";
import { JobApplicationForm } from "@/components/forms/job-application-form";
import { JobApplicationFormWithSnackbar } from "@/components/forms/job-application-form/with-snackbar";
import { Column, Grid, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import type { JobApplicationForm as JobApplicationFormType } from "@/types/contentful-api";
import type { TextCollection } from "@/types/contentful-api";

export const JobApplicationFormSlot = ({
	isAgentApplicationForm,
	jobLocationsCollection,
	shouldShowSnackbar,
	textCollection,
}: JobApplicationFormType & { textCollection: TextCollection }) => {
	const Component = shouldShowSnackbar ? JobApplicationFormWithSnackbar : JobApplicationForm;
	const props = {
		jobLocations: jobLocationsCollection.items,
		isAgentApplicationForm,
		textCollection,
		...(!shouldShowSnackbar && { formType: ApplicationFormType.QUICK }),
	};

	return (
		<Grid>
			<Row>
				<Column s={0} m={1} l={3} />
				<Column m={6}>
					<Component {...props} />
					<Spacer spacing="xxxs" />
				</Column>
			</Row>
		</Grid>
	);
};
