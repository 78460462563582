import React from "react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useTranslation } from "next-i18next";

import Image from "next/image";

import { ActionButtonLink } from "@/components/action-button-link";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { options } from "@/components/rich-text";
import { ColoredSlotWrapper } from "@/components/slot/slots/wrappers";
import { isDark } from "@/components/slot/utils";
import { Typography } from "@/components/typography/typography";
import { SlideshowXMedia } from "@/design-system/organisms/slideshow-x";
import { BackgroundColor, FONT_WEIGHT, TypographyVariant } from "@/theme";
import type { PictureText as PictureTextSlotProps } from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";
import { getComputedAspectRatio } from "@/utils/aspect-ratio";

export const PictureTextSlot = (props: PictureTextSlotProps) => {
	const {
		headline,
		flip,
		text: { json },
		mediaAssetImage,
		picture,
		navigationItem,
	} = props;

	const backgroundPrimary = props.backgroundPrimary ?? "white";
	const backgroundSecondary = props.backgroundSecondary ?? backgroundPrimary;
	const richText = React.useMemo(() => {
		return documentToReactComponents(json, options);
	}, [json]);

	// A PictureText content-type can have a Picture or a Media Asset
	const hasImage = Boolean(picture) || Boolean(mediaAssetImage);
	const imageOptions = {
		alt: picture?.alt ?? mediaAssetImage?.description,
		src: picture?.file?.url ?? mediaAssetImage?.url,
		fit: (picture?.fit as AssetFit) ?? AssetFit.fill,
		f: (picture?.focus as AssetFocus) ?? AssetFocus.faces,
		aspectRatio: getComputedAspectRatio(props?.aspectRatio ?? "1"),
		source: picture?.source,
	};

	return (
		<ColoredSlotWrapper
			primary={BackgroundColor[backgroundPrimary]}
			secondary={BackgroundColor[backgroundSecondary]}
		>
			<Grid>
				<Row>
					<Column l={5}>
						{headline && (
							<>
								<Hidden s m>
									<Spacer spacing="xs" />
								</Hidden>
								<Typography
									tight
									component="h2"
									variant={TypographyVariant.headlineSerifLG}
								>
									{headline}
								</Typography>
							</>
						)}
						{richText}
						{navigationItem && (
							<>
								<ActionButtonLink
									navigationItem={navigationItem}
									dark={isDark(backgroundPrimary)}
								/>
								<Hidden l>
									<Spacer spacing="xxxl" />
								</Hidden>
							</>
						)}
					</Column>
					<Column l={0} order={-1}>
						{hasImage && <PictureTextPicture {...imageOptions} />}
					</Column>
					<>
						<Column s={0} l={1} order={flip ? -1 : undefined} />
						<Column s={0} l={6} order={flip ? -2 : undefined}>
							{hasImage && <PictureTextPicture {...imageOptions} />}
						</Column>
					</>
				</Row>
			</Grid>
		</ColoredSlotWrapper>
	);
};

const PictureTextPicture: React.VFC<{
	alt: string;
	src: string;
	fit: AssetFit;
	f: AssetFocus;
	aspectRatio: number;
	source: string;
}> = ({ aspectRatio, source, ...props }) => {
	const { t } = useTranslation("common");
	return (
		<>
			<SlideshowXMedia aspectRatio={aspectRatio}>
				<Image
					loading="lazy"
					src={`${props.src}?q=75&w=1200&h=1200&fm=${AssetFormat.webp}&f=${props.f}&fit=${props.fit}`}
					alt={props.alt}
					layout="fill"
					objectFit="cover"
					sizes="(max-width: 1199px) 100vw, 45vw"
				/>
			</SlideshowXMedia>
			{source && (
				<>
					<Spacer spacing="xxxs" />
					<Typography variant={TypographyVariant.bodySM} weight={FONT_WEIGHT.light}>
						{t("common:source")}: {source}
					</Typography>
				</>
			)}
		</>
	);
};
