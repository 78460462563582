import React from "react";

import styled from "@emotion/styled";

import Link from "next/link";

import { StyledLinkHintIcon } from "@/components/contentful/components/people/components";
import { Column, Hidden, Row } from "@/components/grid";
import { ImageCard } from "@/components/image-card";
import { ImageCard as ImageCardDiv, ImageCardLink } from "@/components/image-card/components";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import dirs from "@/contentful/content-model/dirs";
import type { Locale } from "@/contentful/content-model/types";
import { Dirs } from "@/contentful/content-model/types";
import { TypographyVariant } from "@/theme";
import type { Maybe, Person } from "@/types/contentful-api";
import { JobDepartment } from "@/types/person";

const ArrowSection = styled.div`
	display: flex;
`;

const FlexTypography = styled(Typography)`
	flex: 1;
`;

const FlexArrow = styled.div`
	display: inline-flex;
	align-self: flex-end;
`;

export const People: React.FC<{
	locale: Locale;
	team: Array<Maybe<Person>>;
}> = ({ locale, team }) => {
	return (
		<Row>
			{team.map(
				({
					sys: { id },
					slug,
					headquarter,
					teamCollection: { items: teamItems },
					...person
				}) => {
					const shouldLink =
						person.jobDepartment === JobDepartment.realEstate &&
						person.slotsCollection.items.length > 0;

					const WrappingComponent: React.FC = shouldLink
						? // TODO: This inline function should be a standalone component,
						  //  it's redefined now on each iteration through array
						  ({ children }) => (
								<Link
									passHref
									href={`/${dirs[Dirs.ourAgents].dir[locale]}/${
										headquarter.slug
									}/${slug}`}
									locale={locale}
								>
									<ImageCardLink>{children}</ImageCardLink>
								</Link>
						  )
						: ImageCardDiv;
					return (
						<Column key={id} s={4} l={3}>
							<WrappingComponent>
								<ImageCard vertical image={person.featuredImage}>
									<Typography
										tight
										component="h2"
										variant={TypographyVariant.headlineSansXXS}
									>
										{person.name}
									</Typography>
									<Typography tight>{person.jobPosition}</Typography>
									<ArrowSection>
										{teamItems.length > 0 && (
											<FlexTypography tight>
												{teamItems
													.filter(Boolean)
													.map(({ name }) => name)
													.join(", ")}
											</FlexTypography>
										)}
										{shouldLink && (
											<FlexArrow>
												<StyledLinkHintIcon icon="arrowRight" />
											</FlexArrow>
										)}
									</ArrowSection>
								</ImageCard>
							</WrappingComponent>
							<Hidden s l>
								<Spacer spacing="xs" />
							</Hidden>
							<Hidden m>
								<Spacer spacing="xxs" />
							</Hidden>
						</Column>
					);
				}
			)}
		</Row>
	);
};
